* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("fonts/JosefinSans-ThinItalic.woff2") format("woff2"),
    url("fonts/JosefinSans-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("fonts/JosefinSans-Thin.woff2") format("woff2"),
    url("fonts/JosefinSans-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Princess Sofia";
  src: url("fonts/PrincessSofia.woff2") format("woff2"),
    url("fonts/PrincessSofia.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hind Madurai";
  src: url("fonts/HindMadurai-Light.woff2") format("woff2"),
    url("fonts/HindMadurai-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hind Madurai";
  src: url("fonts/HindMadurai-Medium.woff2") format("woff2"),
    url("fonts/HindMadurai-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hind Madurai";
  src: url("fonts/HindMadurai-Bold.woff2") format("woff2"),
    url("fonts/HindMadurai-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hind Madurai";
  src: url("fonts/HindMadurai-SemiBold.woff2") format("woff2"),
    url("fonts/HindMadurai-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hind Madurai";
  src: url("fonts/HindMadurai-Regular.woff2") format("woff2"),
    url("fonts/HindMadurai-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tilt Prism";
  src: url("fonts/TiltPrism-Regular.woff2") format("woff2"),
    url("fonts/TiltPrism-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
